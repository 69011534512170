import { useCallback, useState, ComponentPropsWithoutRef, ElementType } from 'react'
import { useNavigation } from 'react-router-dom'
import { cn } from 'utils'
import styles from './loader-wrapper.module.scss'
import { Loader } from '../BaseLoder'
import { LoadingContextProvider } from '../loading-context'

type Tag = ElementType

type Props<T extends Tag> = {
  tagName?: T
  loading?: boolean
} & ComponentPropsWithoutRef<T>

export function LoaderWrapper<T extends Tag>({
  children,
  className,
  tagName,
  loading = false,
  ...props
}: Props<T>) {
  const navigation = useNavigation()
  const globalLoading = navigation.state === 'loading'
  const [innerLoading, setLoading] = useState(false)
  const startLoading = useCallback(() => setLoading(true), [])
  const completeLoading = useCallback(() => setLoading(false), [])
  const isLoading = loading || innerLoading || globalLoading
  const Component = tagName ?? 'div'

  return (
    <Component
      className={cn(styles.container, isLoading && styles.loading, className)}
      {...props}
      aria-busy={loading ? 'true' : 'false'}
      aria-live="polite"
    >
      <LoadingContextProvider
        onStarted={startLoading}
        onCompleted={completeLoading}
        onFailed={completeLoading}
      >
        {children}
      </LoadingContextProvider>
      {isLoading && <Loader />}
    </Component>
  )
}
