import { Client, DeleteConfig, GetConfig, PostConfig } from 'client'
import { every, not } from 'utils/compose'
import { ListQuery } from 'utils/list'
import { Guarantor } from './guarantor'
import { User } from '../user/user'
import { AdminUser } from '../user/user.admin'

export interface Guarantee
  extends Pick<User, 'created_at' | 'current_address' | 'email' | 'first_name' | 'last_name'> {
  user_id: ''
}

export namespace Guarantee {
  export type Sort =
    | 'guarantee_id'
    | 'guarantor_id'
    | 'guarantor_email'
    | 'accepted_at'
    | 'created_at'

  export type Query = ListQuery<Sort, { guarantor_id?: string[] }>

  export interface Request {
    accepted_at?: string | null
    declined_at?: string | null
    guarantee_id: string
    created_at: string
    guarantee: Guarantee
    guarantor?: Guarantee
    guarantor_id: string
  }

  export const enum Status {
    Declined = 'declined',
    Confirmed = 'confirmed',
    Pending = 'pending',
  }

  export const byGuaranteeId =
    (guarantee_id: string) =>
    (request: Request): boolean =>
      request.guarantee_id === guarantee_id

  export const byGuarantorId =
    (guarantor_id: string) =>
    (request: Request): boolean =>
      request.guarantor_id === guarantor_id

  export const byUserGuarantor = (user: User) =>
    every(
      byGuaranteeId(user.user_id),
      user.guarantor_id ? byGuarantorId(user.guarantor_id) : (_: Request) => false,
    )

  export const isConfirmed = (guarantor: Request) => !!guarantor.accepted_at
  export const isDeclined = (guarantor: Request) => !!guarantor.declined_at
  export const isPending = (guarantor: Request) => !isConfirmed(guarantor) && !isDeclined(guarantor)

  export const getStatus = (requsts: Request | Request[]): Status => {
    if (Array.isArray(requsts)) {
      let hasPending = false
      for (const request of requsts) {
        if (request.accepted_at) return Status.Confirmed
        if (!isDeclined(request)) hasPending = true
      }
      return hasPending ? Status.Pending : Status.Declined
    }
    return isConfirmed(requsts)
      ? Status.Confirmed
      : isDeclined(requsts)
      ? Status.Declined
      : Status.Pending
  }

  export const getStatusLabel = (status: Status): string =>
    ({
      [Status.Declined]: 'Declined',
      [Status.Confirmed]: 'Verified',
      [Status.Pending]: 'Pending',
    }[status])

  export interface Detailed {
    user: AdminUser
    request: Request
    status: Status
  }

  export interface Data {
    guarantee_email: string
  }

  export const isUserActionRequired = (requests: Request[] | undefined, { user_id }: User.Id) =>
    !!requests && requests.filter(byGuarantorId(user_id)).some(isPending)
}

export class GuaranteeBackend extends Client {
  create = async (data: Guarantor.Data, config?: PostConfig): Promise<void> => {
    await this.post<Guarantor.Data, { status: string }>('/guarantee/new', data, config)
  }

  accept = async (data: Guarantee.Data, config?: PostConfig): Promise<void> => {
    await this.post<Guarantee.Data, { status: string }>('/guarantee/accept', data, config)
  }

  decline = async (data: Guarantee.Data, config?: PostConfig): Promise<void> => {
    await this.post<Guarantee.Data, { status: string }>('/guarantee/decline', data, config)
  }

  list = async (query: Guarantee.Query = {}, config?: PostConfig): Promise<Guarantee.Request[]> => {
    type Result = { guarantees: Guarantee.Request[]; status: string }
    const { guarantees } = await this.post<Guarantee.Query, Result>(
      '/guarantee/get',
      query ?? null,
      config,
    )
    return guarantees
  }

  /**
   * Get user's guarantor request.
   * @returns `Guarantee.Request`\
   * where `guaranteeRequest.guarantee_id === user.user_id`\
   * and   `declined_at` is blank
   */
  byUserId = async (user_id: string, config?: PostConfig): Promise<Guarantee.Request | null> => {
    const guaranteeRequests = await this.list(
      {
        filter: { guarantee_id: [user_id] },
        order: [
          { name: 'accepted_at', desc: true },
          { name: 'created_at', desc: true },
        ],
      },
      config,
    )
    return guaranteeRequests.find(not(Guarantee.isDeclined)) ?? guaranteeRequests[0] ?? null
  }

  count = async (query: Guarantee.Query = {}, config?: PostConfig): Promise<number> => {
    const { count } = await this.post<Guarantee.Query, { count: number; status: 'success' }>(
      '/guarantee/count',
      query ?? null,
      config,
    )
    return count
  }

  removeGuarantor = async (config?: DeleteConfig): Promise<void> => {
    await this.delete('/guarantee/delete', config)
  }

  byId = async (id: string, config?: GetConfig): Promise<Guarantee.Request> => {
    const guarantees = await this.list({ filter: { guarantee_id: [id] } }, config)
    if (!guarantees.length) throw new Error('Not found')
    return guarantees[0]
  }
}

export const guarantee = new GuaranteeBackend()
