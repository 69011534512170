import { FC, ComponentProps } from 'react'
import { cn } from 'utils'
import styles from './dropdown-menu.module.scss'
import { Dropdown } from '../dropdown'

interface Props extends ComponentProps<typeof Dropdown.External> {
  theme?: 'warn'
}
export const DropdownExternalLink: FC<Props> = ({ className, theme, ...props }) => {
  return (
    <Dropdown.External {...props} className={cn(styles.link, theme && styles[theme], className)} />
  )
}
