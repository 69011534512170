import { FC, ReactNode, useEffect, useId, useMemo, useState, useTransition } from 'react'
import { DropdownProvider } from './context'

interface Props {
  children?: ReactNode | ReactNode[]
  defaultOpened?: boolean
}

const containers = new Map<string, Api>()
const closeAllBut = (id: string) => {
  containers.forEach((api, key) => {
    if (key !== id) api.close()
  })
}

export const DropdownContainer: FC<Props> = ({ children, defaultOpened = false }) => {
  const [opened, setOpened] = useState(defaultOpened)
  const [opener, setOpener] = useState<HTMLElement>()
  const id = useId()
  const [, startTransaction] = useTransition()
  const api = useMemo<Api>(
    () => ({
      id,
      opened,
      opener,
      toggle: (opener?: HTMLElement) => {
        if (opened) setOpened(false)
        else
          startTransaction(() => {
            closeAllBut(id)
            setOpened(!opened)
            setOpener(opener)
          })
      },
      open: (opener: HTMLElement) =>
        startTransaction(() => {
          closeAllBut(id)
          setOpened(true)
          setOpener(opener)
        }),
      close: () =>
        startTransaction(() => {
          setOpened(false)
          setOpener(undefined)
        }),
    }),
    [opened, id, opener],
  )
  useEffect(() => {
    containers.set(id, api)
    return () => {
      containers.delete(id)
    }
  })

  return <DropdownProvider value={api}>{children}</DropdownProvider>
}

type Api = {
  id: string
  opened: boolean
  toggle(opener?: HTMLElement): void
  open(opener?: HTMLElement): void
  close(): void
  opener?: HTMLElement
}
