import { AxiosRequestConfig } from 'axios'
import { Client, GetConfig, PostConfig, convertToServerData } from 'client'
import { Observable } from 'utils/observable'
import { User } from '../user/user'

export class CurrentUserBackend<CurrentUser extends User> extends Client {
  #user = new Observable<CurrentUser>()
  #setReady?: (value: CurrentUser | null) => void
  #ready = new Promise<CurrentUser | null>((resolve) => {
    this.#setReady = resolve
  })

  currentUser = async (): Promise<CurrentUser | null> => {
    await this.#ready
    return this.#user.get()
  }

  async requestUserRecord(config?: Omit<AxiosRequestConfig, 'method'>): Promise<User | null> {
    const { user } = await this.get<{
      user: Omit<User, 'context'>
      context?: { invited_by?: string; unit_id?: string }
    }>('/user/get', config?.params, config)
    return user
  }

  async replaceUser(user: CurrentUser | null): Promise<CurrentUser | null> {
    if (!user) {
      await this.logout()
      return null
    }
    this.#user.set(user)
    return user
  }

  addUserListener = (listener: (user: CurrentUser | null) => void): (() => void) => {
    this.#ready.then(() => {
      this.#user.addListener(listener)
    })
    return () => this.#user.removeUserListener(listener)
  }

  async init(user: User, config?: GetConfig): Promise<CurrentUser> {
    return user as CurrentUser
  }

  fetchUser = async (config?: Omit<AxiosRequestConfig, 'method'>): Promise<CurrentUser | null> => {
    const authorized = await this.isAuthorized()
    if (!authorized) {
      this.#user.set(null)
      this.#setReady?.(null)
      return null
    }
    const user = await this.requestUserRecord(config)
    if (!user) throw new Error('User not found')
    const currentUser = await this.init(user, config)
    this.#user.set(currentUser)
    this.#setReady?.(currentUser)
    return currentUser
  }

  logout = async (): Promise<void> => {
    try {
      await Client.auth.signOut()
    } catch (e) {
      // nothing
    } finally {
      this.#user.set(null)
    }
  }

  updateUser = async (data: User.Update, config?: PostConfig): Promise<User> => {
    const user = await this.post<{ updates: User.Update }, { user: User }>(
      '/user/update',
      {
        updates: convertToServerData(data, {
          date: ['dob'],
          string: ['first_name', 'last_name', 'phone_number', 'employer'],
        }),
      },
      config,
    ).then(({ user }) => this.init(user))
    if (!user) throw new Error('User not found')
    this.replaceUser(user)
    return user
  }

  deleteAccount = async (): Promise<void> => {
    await this.delete('/user/delete')
    await this.logout()
  }
}
