import { createContext, FC, ReactNode, useContext, useMemo } from 'react'

type Value = {
  started(): void
  failed(): void
  completed(): void
}

const noop = () => {}

export const LoadingContext = createContext<Value>({
  started: noop,
  failed: noop,
  completed: noop,
})

interface Props {
  onStarted?: () => void
  onCompleted?: () => void
  onFailed?: () => void
  children?: ReactNode | ReactNode[]
}

export const LoadingContextProvider: FC<Props> = ({
  onStarted: started = noop,
  onCompleted: completed = noop,
  onFailed: failed = noop,
  children,
}) => {
  const api = useMemo(
    () => ({
      started,
      failed,
      completed,
    }),
    [completed, failed, started],
  )

  return <LoadingContext.Provider value={api}>{children}</LoadingContext.Provider>
}

export function wrapInLoadingContext<A extends [], R>(
  context: Value,
  fn: (...args: A) => Promise<R>,
) {
  return async (...args: A): Promise<R> => {
    context.started()
    try {
      const result = await fn(...args)
      context.completed()
      return result
    } catch (e) {
      context.failed()
      throw e
    }
  }
}

export function useLoadingContextWrapper<A extends [], R>(fn: (...args: A) => Promise<R>) {
  const context = useContext(LoadingContext)
  return wrapInLoadingContext(context, fn)
}
