import { Client, GetConfig, PostConfig } from 'client'
import { Application } from './application'

export interface ApplicationFeePayment {
  amount: number
  application_id: string
  created_at: string
  failed_at?: string
  paid_at?: string
  paid_by_id?: string
  payment_reference?: string
  transaction_id?: string
  unit_id: string
}

export namespace ApplicationFeePayment {
  export const enum Method {
    card = 'card',
    bank = 'bank',
  }

  export type Context = { redirectTo?: string; cancelRedirectTo?: string }

  type CreatePaymentApplication = Application.Id & {
    context?: Context
  }

  export type CreateCreditScorePayment = {
    context?: Context
  }

  export type Create = CreatePaymentApplication

  export const isMethodValid = (method: string): method is Method =>
    [Method.card, Method.bank].includes(method as Method)
}

class ApplicationFeePaymentBackend extends Client {
  /**
   * @see https://api-dev.rello.co/swagger/index.html#/payments/post_lease_payments_checkout_session
   */
  create = async (data: ApplicationFeePayment.Create, config?: PostConfig): Promise<string> => {
    const { url } = await this.post<
      ApplicationFeePayment.Create,
      { url: string; status: string; transaction_id: string }
    >('/lease/payments/checkout/session', data, config)
    return url
  }
  /**
   * @see https://api-dev.rello.co/swagger/index.html#/payments/get_application_fee_checkout_session
   */
  createCreditScorePayment = async (
    { context, ...data }: ApplicationFeePayment.CreateCreditScorePayment = {},
    config?: PostConfig,
  ): Promise<string> => {
    type Req = { context?: string }
    type Res = { status: string; transaction_id: string; url: string }
    const { url } = await this.get<Res, Req>(
      '/application/fee/checkout/session',
      { ...data, ...(context && { context: JSON.stringify(context) }) },
      config,
    )
    return url
  }
  /**
   * Verify user paid for application fees (credit score) in the last 30 days
   *  @see https://api-dev.rello.co/swagger/index.html#/user/get_application_fee_verify
   */
  verifyCreditScorePayment = async (config?: GetConfig): Promise<boolean> => {
    const { paid } = await this.get<{ paid: boolean }, any>(
      '/application/fee/verify',
      undefined,
      config,
    )
    return paid ?? false
  }
}

export const applicationFeePayment = new ApplicationFeePaymentBackend()
