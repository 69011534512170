import { ComponentProps } from 'react'
import {
  BaseHtmlValidationInput,
  BaseHtmlValidationProps,
} from '../input-text/base-html-validation-input'

export type NumberInputProps = Omit<
  ComponentProps<'input'>,
  'type' | 'ref' | keyof BaseHtmlValidationProps<number>
> &
  BaseHtmlValidationProps<number>

export class NumberInput extends BaseHtmlValidationInput<
  number,
  NumberInputProps,
  HTMLInputElement
> {
  static defaultProps = {
    validationMessages: {},
  }

  prevent = (event: WheelEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  parse(value: string): number | null {
    const str = (value ?? '').trim()
    if (!str) return null
    const number = Number(str)
    return Number.isNaN(number) ? null : number
  }

  isEmpty(value: number | null | undefined): boolean {
    return value === null || value === undefined || Number.isNaN(value)
  }

  getValidationMessage() {
    return this.checkValidityProps(VALIDITY_PROPS) || this.checkCustomValidity()
  }

  componentDidMount() {
    super.componentDidMount?.()
    this.element?.addEventListener('wheel', this.prevent)
  }

  componentWillUnmount() {
    super.componentWillUnmount?.()
    this.element?.removeEventListener('wheel', this.prevent)
  }

  render() {
    const { validation, validationMessages, validationMessage, defaultValue, ...props } = this.props
    return (
      <input
        {...props}
        defaultValue={this.format(defaultValue)}
        ref={this.setElement}
        id={props.id || props.name}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        type="number"
      />
    )
  }
}

const VALIDITY_PROPS: (keyof ValidityState)[] = [
  'valueMissing',
  'typeMismatch',
  'stepMismatch',
  'rangeOverflow',
  'rangeUnderflow',
]
