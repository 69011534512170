import { DetailedHTMLProps, ButtonHTMLAttributes, forwardRef } from 'react'
import { cn } from 'utils'
import styles from './button.module.scss'
import iconButtonStyle from './icon-button.module.scss'

type HTMLButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

type ButtonTheme =
  | 'primary'
  | 'secondary'
  | 'warn'
  | 'link'
  | 'secondaryLink'
  | 'iconButton'
  | 'flat'
  | 'none'
export interface ButtonProps {
  theme?: ButtonTheme
  wide?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps & HTMLButtonProps>(
  ({ className, children, type = 'button', wide, theme, ...props }, ref) => {
    return (
      <button
        className={cn(
          styles.button,
          theme && (theme === 'iconButton' ? iconButtonStyle : styles)[theme],
          wide && styles.wide,
          className,
        )}
        type={type}
        {...props}
        ref={ref}
      >
        {children}
      </button>
    )
  },
)
Button.displayName = 'Button'
