import { IconClose } from 'icons'
import { ComponentProps, MouseEventHandler } from 'react'
import { cn } from 'utils'
import styles from './file-field.module.scss'
import { IconButton } from '../../../button'
import { FileInput } from '../../input-file'
import { BaseField, BaseFieldProps, withFormDefaultValues } from '../_base'

type FileInputProps = ComponentProps<typeof FileInput>
type Props = FileInputProps & BaseFieldProps<FileInput.Value>
type State = {
  preview: File | null
}
export class Field extends BaseField<string, Props, State> {
  static displayName = 'FileField'

  state = {
    preview: null,
  }

  onChange: FileInputProps['onChange'] = ({ value }) => {
    const file = !value ? null : Array.isArray(value) ? value[0] : value
    this.setState({ preview: file })
  }

  clear: MouseEventHandler = (event) => {
    const input = this.element?.current as FileInput | undefined
    if (!input) return
    event.preventDefault()
    event.stopPropagation()
    this.setState({ preview: null })
    input.setValue(null)
  }

  get className() {
    return cn(super.className, styles.field)
  }

  renderInput({ className, ...props }: FileInputProps) {
    return (
      <>
        <FileInput {...props} onChange={this.onChange} className={cn(className, styles.input)} />
        {this.state.preview && this.renderPreview(this.state.preview)}
      </>
    )
  }

  renderPreview(file: File) {
    const src = file.type.startsWith('image/') ? URL.createObjectURL(file) : null
    if (!src) return null
    return (
      <div className={styles.preview}>
        <img src={src} alt={file.name} className={styles.image} />
        <IconButton onClick={this.clear} className={styles.clear}>
          <IconClose />
        </IconButton>
      </div>
    )
  }
}

export const FileField = withFormDefaultValues<FileInput.Value, Props>(Field)
