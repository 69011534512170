import { FC, ReactNode, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import styles from './portal.module.scss'

interface Props {
  children: ReactNode | ReactNode[]
  scrollLock?: boolean
  rootId?: string
}

const getPortalElement = (rootId: string): HTMLElement => {
  const element = document.getElementById(rootId)
  if (element) return element
  const newElement = document.createElement('div')
  newElement.id = rootId
  newElement.classList.add(styles.portal)
  document.body.appendChild(newElement)
  return newElement
}

export const Portal: FC<Props> = ({ children, scrollLock, rootId = styles.root }) => {
  const appRoot = useRef(document.getElementById('root')).current

  useEffect(() => {
    if (!appRoot) return

    const previouslyFocused = document.activeElement as HTMLButtonElement | undefined
    if (scrollLock) {
      appRoot.setAttribute('inert', 'true')
      document.body.style.overflow = 'clip'
      document.body.style.pointerEvents = 'none'
      return () => {
        appRoot.removeAttribute('inert')
        document.body.style.overflow = ''
        document.body.style.pointerEvents = ''
        previouslyFocused?.focus?.()
      }
    }
    return () => {
      previouslyFocused?.focus?.()
    }
  }, [scrollLock, appRoot])

  return <>{createPortal(<>{children}</>, getPortalElement(rootId))}</>
}
