import { Client, PostConfig } from 'client'
import { ListQuery } from 'utils/list'
import { Address } from './address'

export interface Owner {
  address: Address
  created_at: string
  ein: string
  email: string
  lease_signer_user_id?: string
  name: string
  owner_id: string
  phone_number: string
  allow_nonqualified_applications?: boolean
  property_manager_id?: string
  required_bank_statements?: boolean
  required_proof_of_income?: boolean
}

export namespace Owner {
  export const Singular = 'Owner'
  export const Plural = 'Owners'

  export type IdField = 'owner_id'
  export type Id = Pick<Owner, IdField>

  export type Sort = 'created_at' | 'ein' | 'email' | 'name' | 'owner_id' | 'phone_number'
  export type Query = ListQuery<
    Sort,
    {
      owner_id?: string[]
      user_id?: string[]
      agent_id?: string[]
      property_manager_id?: string[]
    }
  >
  export type Filter = Query['filter']
}

export class OwnerBackend<T extends Owner> extends Client {
  byId = async (id: string, config?: PostConfig): Promise<T> => {
    const { owners } = await this.post<Owner.Query, { owners: T[]; status: string }>(
      '/owner/get',
      { filter: { owner_id: [id] } },
      config,
    )
    if (!owners[0]) throw new Error(`Owner with id ${id} not found`)
    return owners[0]
  }

  byIds = async (owner_id: string[], config?: PostConfig): Promise<T[]> => {
    if (!owner_id.length) return []
    const { owners } = await this.post<Owner.Query, { owners: T[]; status: string }>(
      '/owner/get',
      { filter: { owner_id } },
      config,
    )
    return owners
  }
}

export const owner = new OwnerBackend()
