import { some } from 'utils/compose'
import { Address } from '../src/address'
import { Cosigner } from '../src/co-signers'
import { Guarantor } from '../src/guarantor'
import { Plaid } from '../src/plaid'
import { UserDocument } from '../user-document/user-document'

export interface User {
  additional_tenants?: number
  adjusted_score?: number
  /** Overrides assets for a user */
  assets_override?: number
  /** Confidence (0-100) */
  behavioral_score?: number
  bypass_credit_check?: boolean
  cosigner?: Cosigner
  created_at: string
  credit_score?: number
  current_address?: Address
  dob?: string
  email: string
  employer?: string
  finance?: UserFinance
  first_name?: string
  guarantor_id?: string
  identity_match_score?: number
  /** Overrides income for a user */
  income_override?: number
  invite_hash?: string
  invite_unit_id?: string
  last_name?: string
  pets?: boolean
  pets_comments?: string
  phone_number?: string
  phone_verified_at?: string
  previous_landlord?: string
  reported_gross_income?: number
  roles?: UserRole[]
  score?: number
  /** Overrides income for a user */
  score_override?: number
  terms_accepted_at?: string
  user_id: string
  /** When {true} user will be able to submit applications regardless of score. */
  voucher?: boolean
}

export const enum UserRole {
  Agent = 'RELLO_ROLE_AGENT',
  Owner = 'RELLO_ROLE_OWNER',
  Admin = 'RELLO_ROLE_ADMIN',
  Guarantor = 'RELLO_ROLE_GUARANTOR',
  Support = 'RELLO_ROLE_SUPPORT',
}

export const enum BackgoundCheckStatus {
  Clear = 'CLEAR',
  Consideration = 'CONSIDERATION',
  Rejected = 'REJECTED',
}

export namespace User {
  export type Id = Pick<User, 'user_id'>

  export const byId =
    (id: string) =>
    ({ user_id }: Id) =>
      user_id === id

  export type Update = Partial<
    Pick<
      User,
      | 'additional_tenants'
      | 'current_address'
      | 'dob'
      | 'employer'
      | 'first_name'
      | 'last_name'
      | 'pets'
      | 'pets_comments'
      | 'phone_number'
      | 'phone_verified_at'
      | 'previous_landlord'
      | 'reported_gross_income'
      | 'terms_accepted_at'
    >
  >
  /**
   * Returns {true} when user score is defined.
   * (a condition for user's finacial step completness)
   */
  export const isScoreOverridden = (user?: User | null) => !!user?.score_override
  export const isIncomeOverridden = (user?: User | null) => !!user?.income_override
  export const isAssetsOverridden = (user?: User | null) => !!user?.assets_override
  export const hasOverrides = some(isAssetsOverridden, isIncomeOverridden, isScoreOverridden)

  export const hasScore = (user?: User | null) => !!user?.score || hasOverrides(user)
  export const isProfileComplete = (user?: User | null) => hasScore(user)

  export const getScore = (user?: User | null) => user?.score_override ?? user?.score ?? 0

  export const isGuarantor = (user?: User | null) => !!user?.roles?.includes(UserRole.Guarantor)

  export type Brief = Pick<User, 'first_name' | 'last_name' | 'email' | 'user_id'>
}

export interface UserFinance {
  guarantor?: Guarantor
  bank_accounts?: Plaid.BankAccount[]
  documents?: UserDocument[]
}
