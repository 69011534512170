import { Cosigner } from './co-signers'
import { CurrentUser } from './current-user.hello'

export interface Guarantor {
  guarantee_id: string
  email: string
  accepted_at?: string | null
  declined_at?: string | null
  created_at: string
  guarantor_id: string
  first_name?: string
  last_name?: string
}

export namespace Guarantor {
  export interface Data {
    guarantor_email: string
    guarantor_phone_number: string
  }

  export function createNewGuarantorValidator({
    user,
    cosigners,
  }: {
    user: CurrentUser
    cosigners?: Cosigner[]
  }) {
    return (email: string | null) => {
      if (email === user.email) return 'You cannot add yourself as a guarantor'
      if (email && cosigners?.some((cosigner) => cosigner.user.email === email))
        return 'This user is already a cosigner'
      return null
    }
  }
}
