import { ComponentProps, FC } from 'react'
import { useNavigation } from 'react-router-dom'
import { LoaderWrapper } from 'ui'
import { cn } from 'utils'
import styles from './page.module.scss'

type Props = ComponentProps<'div'> & {
  tagName?: ComponentProps<typeof LoaderWrapper>['tagName']
}

export const Page: FC<Props> = ({ className, children, tagName = 'main', ...props }) => {
  const { state } = useNavigation()

  return (
    <LoaderWrapper
      tagName={tagName}
      className={cn(styles.page, className)}
      loading={state === 'loading'}
      {...props}
    >
      {children}
    </LoaderWrapper>
  )
}
